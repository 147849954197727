import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

import './index.css'
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const HEXGONAL_TEXT_COLOR = theme.palette.mode === 'light' ? '#FFFFFF' : '#231f20'
  const HEXGONAL_FILL_COLOR = theme.palette.mode === 'light' ? '#231f20' : "#FFFFFF"

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 140,
        height: 50,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 240.09 46.43">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <g>
              <text
                fill={HEXGONAL_FILL_COLOR}
                fontFamily="Public Sans,sans-serif;"
                fontSize="35.73"
                fontWeight={500}
                transform="translate(46.68 34.95)"
              >
                <tspan x="0" y="0">Workcules</tspan></text>
              <g>
                <polygon points="38.21 33.84 20.11 44.29 2 33.84 2 12.93 20.11 2.48 38.21 12.93 38.21 33.84" fill={HEXGONAL_FILL_COLOR} stroke={HEXGONAL_FILL_COLOR} strokeMiterlimit="10" strokeWidth="4" />
                <polygon points="15.07 34.65 12.09 34.65 6.35 12.12 9.44 12.12 15.07 34.65" fill={HEXGONAL_TEXT_COLOR} />
                <polygon points="27.49 34.65 24.48 34.65 18.7 12.12 21.74 12.12 27.49 34.65" fill={HEXGONAL_TEXT_COLOR} />
                <polygon points="27.61 34.65 25.02 34.65 30.89 12.12 33.86 12.12 27.61 34.65" fill={HEXGONAL_TEXT_COLOR} />
                <polygon points="15.17 34.65 12.58 34.65 18.45 12.12 21.42 12.12 15.17 34.65" fill={HEXGONAL_TEXT_COLOR} />
              </g>
            </g>
            <text fontWeight={400} fontSize="16.65" transform="translate(98.24 53.34)">
              <tspan x="4.64" y="0" fill={HEXGONAL_FILL_COLOR}>for Employers</tspan>
            </text>
            <g>
              <rect
                x="226.68"
                width="11.38"
                height="53.34"
                fill='#21409a'
              />
              <text
                fontFamily="Public Sans,sans-serif;"
                fontSize="10.89"
                fontWeight={700}
                fill='#fff'
                transform="translate(205.43 10.39) scale(1.1 1)">
                <tspan x="20.89" y="0">B</tspan>
                <tspan x="20.89" y="13.07">E</tspan>
                <tspan x="20.89" y="26.13">T</tspan>
                <tspan x="20.89" y="39.2">A</tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
