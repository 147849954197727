import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children, image }) {

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
      }}
      alignItems="center"
    >
      <Logo disabledLink
        sx={{
          // m: { xs: 2, md: 5 },
          width: 200, height: 150
        }}
      />
      

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Box p={3}>
      <img
        alt="auth"
        sizes="(min-width: 400px) 80vw, 100vw"
        src={image}
      />
    </Box>
  );

  return (
    <Stack spacing={3}>

      {renderContent}

      {renderSection}
    </Stack>
  );
}

AuthModernLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};
