import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const MiniLogo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const HEXGONAL_TEXT_COLOR = theme.palette.mode === 'light' ? '#FFFFFF' : '#231f20'
  const HEXGONAL_FILL_COLOR = theme.palette.mode === 'light' ? '#231f20' : "#FFFFFF"

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 140,
        height: 120,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.61 46.77">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <g>
              <polygon points="38.21 33.84 20.11 44.29 2 33.84 2 12.93 20.11 2.48 38.21 12.93 38.21 33.84" fill={HEXGONAL_FILL_COLOR} stroke={HEXGONAL_FILL_COLOR} strokeMiterlimit="10" strokeWidth="4" />
              <polygon points="15.07 34.65 12.09 34.65 6.35 12.12 9.44 12.12 15.07 34.65" fill={HEXGONAL_TEXT_COLOR} />
              <polygon points="27.49 34.65 24.48 34.65 18.7 12.12 21.74 12.12 27.49 34.65" fill={HEXGONAL_TEXT_COLOR} />
              <polygon points="27.61 34.65 25.02 34.65 30.89 12.12 33.86 12.12 27.61 34.65" fill={HEXGONAL_TEXT_COLOR} />
              <polygon points="15.17 34.65 12.58 34.65 18.45 12.12 21.42 12.12 15.17 34.65" fill={HEXGONAL_TEXT_COLOR} />
            </g>
            <g>
              <rect fill='#21409a' x="47.23" width="11.38" height="46.77" />
              <text
                fill='#fff'
                fontSize="9.55"
                fontWeight={700}
                fontFamily="Public Sans,sans-serif"
                transform="translate(48.99 9.11) scale(1.26 1)">
                <tspan x="0" y="0">B</tspan>
                <tspan x="0" y="11.46">E</tspan>
                <tspan x="0" y="22.91">T</tspan>
                <tspan x="0" y="34.37">A</tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

MiniLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default MiniLogo;
