import { m } from 'framer-motion';
// @mui
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link'
// utils
import { fToNow } from 'src/utils/format-time';
// _mock
import { _contacts } from 'src/_mock';
// components
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// redux-store
import { useSelector } from 'src/redux/store';
// Domain-Path
import { DomainURL } from 'src/DomainURL';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';


// ----------------------------------------------------------------------

const adminCompanyMenu = [
  { id: 1, label: "Company Info", path: paths.dashboard.employer.companyInfo.view },
  { id: 2, label: "Company Users", path: paths.dashboard.employer.usersList },
  { id: 3, label: "Integrations", path: '#' },
]
const hrcompanyMenu = [
  { id: 1, label: "Company Info", path: paths.dashboard.hr.companyInfo.view }

]
const recruiterCompanyMenu = [
  { id: 1, label: "Company Info", path: paths.dashboard.recruiter.companyInfo.view }

]

export default function ContactsPopover() {
  const popover = usePopover();
  const { userPreview } = useSelector(state => state.userInfo);
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Avatar
          src={userPreview?.organizationPhoto ? `${DomainURL.BaseProfileURL}${userPreview.organizationPhoto}` : ''}
          alt={userPreview?.organizationName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userPreview?.organizationName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200 }} >
        <Typography variant="subtitle1" sx={{ p: 1.5 }}>
          {userPreview?.organizationName}
        </Typography>

        <Scrollbar >
          {userPreview?.systemRole === ("ORG_ADMIN") &&
            adminCompanyMenu.map((contact) => (
              // eslint-disable-next-line react/self-closing-comp


              <Link
                component={RouterLink}
                href={contact.path}
                underline="none"
                noWrap
                sx={{
                  typography: 'body2',
                  color: 'text.primary',
                  fontSize: 13,
                  transition: (theme) => theme.transitions.create('all'),
                  '&:hover': { color: '#000' },
                }}
              ><MenuItem key={contact.id} sx={{ p: 1 }} onClick={popover.onClose}>{contact.label}</MenuItem></Link>


            ))
          }

          {userPreview?.systemRole === ("ORG_OFFLOAD_ONBOARD_USER") &&
            hrcompanyMenu.map((contact) => (
              // eslint-disable-next-line react/self-closing-comp
              
                <Link
                  component={RouterLink}
                  href={contact.path}
                  underline="none"
                  noWrap
                  sx={{
                    typography: 'body2',
                    color: 'text.primary',
                    fontSize: 13,
                    transition: (theme) => theme.transitions.create('all'),
                    '&:hover': { color: '#000' },
                  }}
                ><MenuItem key={contact.id} sx={{ p: 1 }}>{contact.label}</MenuItem></Link>
            ))
          }
          {userPreview?.systemRole === ("ORG_RECRUITER") &&
            recruiterCompanyMenu.map((contact) => (
              // eslint-disable-next-line react/self-closing-comp
              
                <Link
                  component={RouterLink}
                  href={contact.path}
                  underline="none"
                  noWrap
                  sx={{
                    typography: 'body2',
                    color: 'text.primary',
                    fontSize: 13,
                    transition: (theme) => theme.transitions.create('all'),
                    '&:hover': { color: '#000' },
                  }}
                ><MenuItem key={contact.id} sx={{ p: 1 }}>{contact.label}</MenuItem></Link>
            ))
          }
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
